<template>
    <vs-card>
        {{DateToString(tempInvoice.invoice.created,false)}}
        <h3 class="card-title d-flex"> {{tempInvoice.invoice.bez}}
            <div class="ml-auto text-right">
                <vs-chip :color="sumColor" ><vs-avatar :icon="sumIcon" color="black" @click="SelectAllInvoiceMember(tempInvoice.member)"/>{{GetCurrencyString(offeneSumme)}}</vs-chip>
            </div>
        </h3>
        <div class="card-subtitle">
            <h4>Nr.: {{tempInvoice.invoice.invoice_nr}}</h4>
        </div>
        <vs-collapse type="border" class="mb-3">
            <vs-collapse-item>
                <div slot="header">
                    <h4>Teilbeträge anzeigen</h4>
                </div>
                <div class="clearfix">
                   
                <template v-for="(grp,index) in SplitMemberList(tempInvoice.member)">
                    <div :key="'grp_'+index" class="float-left" style="max-width:350px">
                        <vs-list>
                            <template v-for="(mem,idx) in grp">
                                <PaymentInvoiceBlockMem :key="'grpmem4_'+idx"
                                v-on:SelectedChanged="Recalc()" 
                                :LanguageFk="tempInvoice.invoice.language_fk" 
                                :CurrencyKuerzel="tempInvoice.invoice.currency_kuerzel" 
                                v-model="tempInvoice.member[GetIndexOfMember(tempInvoice.member,mem.ordermember.pos)]">
                                </PaymentInvoiceBlockMem>
                            </template>
                        </vs-list>
                    </div>
                </template>
                </div>

                <div class="clearfix">
                    <template v-for="(mem,index) in tempInvoice.member">
                        <div :key="'tempmem_'+index">                         
                            
                        </div>
                    </template> 
                </div>
            </vs-collapse-item>               
        </vs-collapse>
        <div slot="footer">
            <vs-row vs-justify="flex-end">
            <vs-button :disabled="Math.abs(this.tempInvoice.selectedAmount) <= 0" @click="MarkAsPayed()" type="filled" color="primary" icon="euro">{{$t('PAYMENT.BLOCK.PAY')}}</vs-button>
            </vs-row>
        </div>
    </vs-card>
</template>
<script>

import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';
import PaymentInvoiceBlockMem from './PaymentInvoiceBlockMem.vue';

export default {
    name: "PaymentInvoiceBlock",
    components: {
        PaymentInvoiceBlockMem
    },
    props:[ 'value', 'LanguageFk', 'CurrencyKuerzel' ],
    data: function (){
        return {
            tempInvoice:this.value,
            sumColor:"primary",
            sumIcon:"attach_money"
        }
    },
    mounted(){
        this.Recalc();
    },
    methods:{
        ...helperMethods,
        ...codeHelper,
        GetIndexOfMember(member, id){
            var index = 0;
            if(this.IsNotNull(member))
            {
                for(var i = 0; i < member.length; i++)
                {
                    if(member[i].ordermember.pos == id)
                    {
                        index = i;
                        break;
                    }
                }
            }
            return index;
        },
        SplitMemberList(member)
        {
            var grps = [];
            if(this.IsNotNull(member))
            {
                var listmember = [];
                for(var i = 0; i < member.length; i++)
                {
                    listmember.push(member[i]);

                    if(Number((i+1) % 5) == 0)
                    {
                        grps.push(listmember);
                        listmember = [];
                    }
                }
                
                // Restliche Member einfügen
                grps.push(listmember);

                
            }
            return grps;
        },
        GetCurrencyString(amount)
        {
            if(this.IsNotNull(amount))
            {
                return this.DecimalToString(amount,this.CurrencyKuerzel,this.GetLocalFormatOfLanguage(this.LanguageFk));
            }
            else
            {
                return "";
            }
        },
        SelectAllInvoiceMember()
        {
            this.tempInvoice.member.forEach(mem=>{
                mem.selected = true;
            });
        },
        Recalc(){
            this.tempInvoice.selectedAmount = 0;

            this.tempInvoice.member.forEach(mem => {
                if(mem.selected == true)
                {
                    this.tempInvoice.selectedAmount += (mem.invoicemember.betrag - mem.invoicemember.bezahlt);
                }
            });

            if(this.offeneSumme <= 1 && this.offeneSumme >= -1)
            {
                this.sumColor = "success";
                this.sumIcon = "done";
            }
            else
            {
                this.sumColor = "primary";
                this.sumIcon = "attach_money";
            }
        },
        MarkAsPayed(){
            this.$store.dispatch('accounting/markInvoiceAsPayed',this.tempInvoice)
             .then(response => {
                if(response.success === true)
                {
                    this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PAYMENT.CONNECT.SUCCESS')});  
                    this.$emit('Success');
                }
                else
                {
                this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('PAYMENT.CONNECT.WARNING')}); 
                }
                
            }, 
            error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PAYMENT.CONNECT.ERROR').replace("*1*",error)});  
            });
        }
    },
    computed:{
        offeneSumme(){
            return this.tempInvoice.invoice.amount - this.tempInvoice.invoice.amount_bez - this.tempInvoice.selectedAmount;
        }
    },
    watch:{

    }
};
</script>