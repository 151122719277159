<template>
  <vs-card>
    <vs-row vs-justify="center">
        <vs-icon icon="search" size="large"></vs-icon>
    </vs-row>
    <vs-row vs-justify="center">
        {{Text}}
    </vs-row>
  </vs-card>
</template>
<script>
export default {
  name: "NotFoundCard",
  props:['Text'],
   data: function (){
     return {
     }
  },
  computed:{

  },
  methods:{ 
  },
  watch:{
  }
};
</script>
