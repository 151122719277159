<template>
    <div @click="toggleCheckbox()">
        <vs-card  class="mb-1">
            <vs-list-header :title="'Position '+mem.ordermember.pos"></vs-list-header>
            <vs-list-item :title="mem.ordermember.txt" >
                <vs-checkbox v-show="!isPayed" v-model="mem.selected"></vs-checkbox>
                <vs-checkbox v-show="isPayed" :disabled="isPayed" v-model="isPayed"></vs-checkbox>
            </vs-list-item>
            <vs-list-item subtitle="offener Betrag">
                <vs-chip :color="statusColor">{{GetCurrencyString(mem.invoicemember.betrag - mem.invoicemember.bezahlt)}}</vs-chip>
            </vs-list-item>
        </vs-card>
    </div>
</template>
<script>

import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';

export default {
    name: "PaymentInvoiceBlockMem",
    components: {

    },
    props:[ 'value','LanguageFk', 'CurrencyKuerzel' ],
    data: function (){
        return {
            mem:this.value
        }
    },
    methods:{
        ...helperMethods,
        ...codeHelper,
        GetCurrencyString(amount)
        {
            if(this.IsNotNull(amount))
            {
                return this.DecimalToString(amount,this.CurrencyKuerzel,this.GetLocalFormatOfLanguage(this.LanguageFk));
            }
            else
            {
                return "";
            }
        },
        toggleCheckbox()
        {
            if(!this.isPayed)
            {
                this.mem.selected = !this.isSelected;
            }
        }
    },
    computed:{
        isSelected()
        {
            return this.mem.selected;
        },
        statusColor()
        {
            var color = "primary";
            var offen = this.mem.invoicemember.betrag - this.mem.invoicemember.bezahlt;
            if(Math.abs(offen) > 0 && Math.abs(offen) < Math.abs(this.mem.invoicemember.betrag))
            {
                color = "warning";
            }
            if(Math.abs(offen) <= this.GetPaymentDiff())
            {
                color="success";
            }
            return color;
        },
        isPayed(){
            return (Math.abs(this.mem.invoicemember.betrag - this.mem.invoicemember.bezahlt)) <= this.GetPaymentDiff();
        }
    },
    watch:{
        mem(value){
            this.$emit('input', value);
        },
        isSelected(value)
        {
            this.$emit('SelectedChanged',value)
        }
    }
};
</script>