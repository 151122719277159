<template>
<div>
  <vs-row vs-justify="center">
    <vs-col v-if="!editmode" type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('PAYMENT.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('PAYMENT.HEADER.TEXT')}}</span>
        </p>
        <AddPaymentDlg ref="addPaymentDlg" v-on:Success="PaymentAdded"></AddPaymentDlg>
      </vs-card>
    </vs-col>
    <vs-col v-if="!editmode" type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" vs-xs="12">
      <vs-card >
        <div class="d-flex align-items-center">
          <vs-button @click="$refs.addPaymentDlg.ShowDlg()" class="mt-3 mb-2"  type="filled" icon="add" >{{$t('PAYMENT.ADD')}}</vs-button>
        </div>
        <div id="payments_table" class="vs-con-loading__container" >
          <vs-table
            search
            :data="payments"
            :noDataText="$t('PAYMENT.NO_DATA')"
            stripe
            max-items="50" 
            pagination
            hoverFlat>

            <template slot="header">
              <h3>
                {{$t('PAYMENT.TABLE.HEADER')}}
              </h3>
            </template>
            <template slot="thead">  
              <vs-th></vs-th>                              
              <vs-th sort-key="date_payment">
                {{$t('PAYMENT.TABLE.COL1')}}
              </vs-th>
              <vs-th sort-key="amount">
                {{$t('PAYMENT.TABLE.COL2')}}
              </vs-th>  
              <vs-th sort-key="amount_zugeordnet">
                {{$t('PAYMENT.TABLE.COL3')}}
              </vs-th>                    
              <vs-th sort-key="reference">
                {{$t('PAYMENT.TABLE.COL4')}}
              </vs-th> 
              <vs-th sort-key="iban">
                {{$t('PAYMENT.TABLE.COL5')}}
              </vs-th>  
              <vs-th sort-key="bankname">
                {{$t('PAYMENT.TABLE.COL6')}}
              </vs-th>
              <vs-th sort-key="payed_by_name">
                {{$t('PAYMENT.TABLE.COL7')}}
              </vs-th>                      
              <vs-th></vs-th>                                                                                                                                             
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                <vs-td>
                  <vs-icon :icon="GetIcon(tr)" :color="GetIconColor(tr)" size="small"></vs-icon>
                </vs-td>
                <vs-td :data="tr.date_payment">
                  {{DateToString(tr.date_payment,false)}}
                </vs-td>      
                <vs-td :data="tr.amount">
                  <vs-icon :icon="GetInOutIcon(tr)" :color="GetInOutIconColor(tr)" size="small"></vs-icon>
                  {{GetCurrencyString(tr.amount,tr.language_fk,tr.currency_kuerzel)}}
                </vs-td>                     
                <vs-td :data="tr.amount_zugeordnet">
                  {{GetCurrencyString(tr.amount_zugeordnet,tr.language_fk,tr.currency_kuerzel)}}
                </vs-td>                   
                <vs-td :data="tr.reference">
                  {{tr.reference}}
                </vs-td>  
                <vs-td :data="tr.iban">
                  {{tr.iban}}
                </vs-td>  
                <vs-td :data="tr.bankname">
                  {{tr.bankname}}
                </vs-td> 
                <vs-td :data="tr.payed_by_name">
                  {{tr.payed_by_name}}
                </vs-td>                  
                <vs-td>
                  <div class="d-flex">
                  
                  <vs-button class="mr-2" :disabled="!ShowZuordnungsButton(tr)" @click="GetInvoicesForPayment(tr)" size="small" color="success" type="filled" icon="add_link"></vs-button>
                  <vs-button :disabled="!IsEditAllowed(tr)" class="ml-2" @click="EditPayment(tr)" size="small" type="filled" icon="edit"></vs-button>
                  <vs-button class="ml-2" @click="DeletePayment(tr)" size="small" type="filled" icon="delete"></vs-button>
                  </div>
                </vs-td>                                                                                                                                                                                          
              </vs-tr>
            </template>
          </vs-table> 
        </div> 
      </vs-card>
    </vs-col>
  </vs-row>
   <vs-row vs-justify="center" v-show="connectMode">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" vs-xs="12">
      <vs-card>
         <h2 class="card-title d-flex">{{$t('PAYMENT.HEADER.ZUORDNUNG')}}
          <div class="ml-auto text-right">
            <vs-chip color="success"><h5>{{GetCurrencyString(selectedPayment.amount - selectedAmount,selectedPayment.language_fk,selectedPayment.currency_kuerzel)}}</h5></vs-chip>
          </div>
        </h2>
        <div class="card-subtitle">
          <span>{{$t('PAYMENT.HEADER.ZUORDNUNG.TEXT')}}</span>
        </div>
        <vs-collapse type="border" class="mb-3">
          <vs-collapse-item icon-arrow="search">
            <div slot="header">
              {{$t('PAYMENT.SEARCH_MANUELL')}}
            </div>
            <div class="default-input clearfix align-items-center mb-3">
              <vs-input :label="$t('PAYMENT.ADD.LABEL.REF')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENT.ADD.LABEL.PLACEHOLDER.REF')" v-model="searchReference"/>
              <vs-input :label="$t('PAYMENT.ADD.LABEL.PAYED_BY')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENT.ADD.LABEL.PLACEHOLDER.PAYED_BY')" v-model="searchPayed_by_name"/>
              <vs-input type="number" :label="$t('PAYMENT.ADD.LABEL.AMOUNT')"  class="float-left mr-2 inputx" :placeholder="0" v-model="searchAmount" />
            </div>
            <div class="default-input clearfix align-items-center mb-3">
              <vs-input :label="$t('PAYMENT.ADD.LABEL.IBAN')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENT.ADD.LABEL.PLACEHOLDER.IBAN')" v-model="searchIban"/>
              <vs-input :label="$t('PAYMENT.ADD.LABEL.BANKNAME')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENT.ADD.LABEL.PLACEHOLDER.BANKNAME')" v-model="searchBankname"/>
            </div>
            <div class="default-input clearfix align-items-center mb-3">
              <vs-button @click="LookForInvoices()" icon="search" class="float-left mr-2 inputx" type="filled">{{$t('PAYMENT.SEARCH')}}</vs-button>
              <vs-button @click="ClearSearch()" icon="clear" class="float-left mr-2 inputx" type="filled">{{$t('COM.CLEAR')}}</vs-button>
            </div>
          </vs-collapse-item>
        </vs-collapse>
        <div class="d-flex mb-3">
          <vs-chip color="success">{{$t('PAYMENT.AVAILABLE_AMOUNT')}}</vs-chip>
          <vs-chip color="primary">{{$t('PAYMENT.OPEN_AMOUNT')}}</vs-chip>
        </div>
        <NotFoundCard v-show="tempInvoices.length == 0" :Text="$t('PAYMENT.NO_PAYMENT_FOUND')"></NotFoundCard>
        <template v-for="(tempInvoice, index) in tempInvoices">
          <div :key="'tmpInvoice_'+index">
            <PaymentInvoiceBlock v-on:Success="MarkInvoiceAsPayedSuccess()" :LanguageFk="tempInvoice.invoice.language_fk" :CurrencyKuerzel="tempInvoice.invoice.currency_kuerzel" v-model="tempInvoices[index]"></PaymentInvoiceBlock>
          </div>
        </template>
      </vs-card>
    </vs-col>
  </vs-row>
</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';
import AddPaymentDlg from './components/dialogs/AddPaymentDlg.vue';
import PaymentInvoiceBlock from './components/PaymentInvoiceBlock.vue';
import NotFoundCard from './components/NotFoundCard.vue';
import {paymentType} from '../helper/enumHelper';

export default {
  name: "Payments",
  components: {
    AddPaymentDlg,
    PaymentInvoiceBlock,
    NotFoundCard
  },  
    data: function (){
      return {
      payments:[],
      editmode:false,
      tempInvoices:[],
      selectedPayment:{},
      searchReference:"",
      searchPayed_by_name:"",
      searchAmount:0,
      searchIban:"",
      searchBankname:"",
      connectMode:false
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.payment.payments.data != null)
      {
        data = this.$store.state.payment.payments.data;
      }
      return data;
    },
    getTableDataInvoices() {
      var data = [];
      if(this.$store.state.accounting.tempInvoices.data != null)
      {
        data = this.$store.state.accounting.tempInvoices.data;
      }
      return data;
    },    
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.payment.payments.status) != 'undefined')
        loading = this.$store.state.payment.payments.status.loading;
      return loading;
    },
    selectedAmount()
    {
      var sum = 0;
      if(this.IsNotNull(this.tempInvoices) && this.tempInvoices)
      {
        this.tempInvoices.forEach(invoice => {
          sum += invoice.selectedAmount;
        });
      }
      return sum;
    }
  },
  watch: {
      getTableData(value) {
          this.payments = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#payments_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#payments_table'});
        }
      },
      getTableDataInvoices(value) {
          this.tempInvoices = value;
      },
    },
  methods: {  
    ...helperMethods,
    ...enumHelper,
    ...codeHelper,
    LoadData(){
      this.$store.dispatch('payment/getAllMyPayments');
    },
    GetCurrencyString(amount,language_fk,currency_kuerzel){
      if(this.IsNotNull(amount) && this.IsNotNull(language_fk) && this.IsNotNull(currency_kuerzel))
      {
        return this.DecimalToString(amount,currency_kuerzel,this.GetLocalFormatOfLanguage(language_fk));
      }
      else
      {
        return "";
      }
    },
    IsEditAllowed(payment)
    {
       var allowed = false;
       if(this.IsNotNull(payment))
       {
         allowed = payment.amount_zugeordnet <= this.GetPaymentDiff();
       }
       return allowed;
    },
    GetInOutIcon(payment){
      
      var icon = "arrow_back";
      if(this.IsNotNull(payment))
      {
        if(payment.payment_type == paymentType.OUTGOING)
        {
          icon = "arrow_forward";
        }
      }
      return icon;
    },
    GetInOutIconColor(payment){
      var color = "green";

      if(this.IsNotNull(payment))
      {
        if(this.IsNotNull(payment))
        {
          if(payment.payment_type == paymentType.OUTGOING)
          {
            color = "red";
          }
        }
      }
      return color;
    }, 
    GetIcon(payment){
      var icon = "priority_high";

      if(this.IsNotNull(payment))
      {

        if(Math.abs(payment.amount_zugeordnet)  <= this.GetPaymentDiff())
        {
          icon = "fiber_new";
        }
        else
        {
          if(Math.abs(payment.amount - payment.amount_zugeordnet)  <= this.GetPaymentDiff())
          {
            icon = "check";
          }
        }
      }
      return icon;
    },
    GetIconColor(payment){
      var color = "primary";

      if(this.IsNotNull(payment))
      {
        if(Math.abs(payment.amount_zugeordnet)  <= this.GetPaymentDiff())
        {
          color = "dark";
        }
        else
        {
          if(Math.abs(payment.amount - payment.amount_zugeordnet)  <= this.GetPaymentDiff())
          {
            color = "success";
          }
        }
      }
      return color;
    },    
    PaymentAdded(){
      this.LoadData();
    },
    GetInvoicesForPayment(payment)
    {
      this.selectedPayment = payment;

      this.searchReference = payment.reference;
      this.searchPayed_by_name = payment.payed_by_name;
      this.searchAmount = payment.amount;
      this.searchIban = payment.iban;
      this.searchBankname = payment.bankname;

      this.LookForInvoices();
    },
    LookForInvoices()
    {
      var data = 
      {
          reference: this.searchReference,
          amount: this.searchAmount,
          iban: this.searchIban,
          name: this.searchPayed_by_name,
          bankname:this.searchBankname,
          id: this.selectedPayment.id
      };

      if(this.IsNotNull(data.amount) == false || data.amount == "")
      {
        data.amount = 0;
      }

      this.$store.dispatch('accounting/getInvoicesForPayment',data);
      this.connectMode = true;
    },
    ClearSearch(){
      this.searchReference = "";
      this.searchPayed_by_name = "";
      this.searchAmount = 0;
      this.searchIban = "";
      this.searchBankname = "";
    },
    ShowZuordnungsButton(payment)
    {
      var show = false;
      if(this.IsNotNull(payment))
      {
        show = payment.isLinked == false || (payment.amount - payment.amount_zugeordnet) >= this.GetPaymentDiff();
      }
      return show;
    },
    EditPayment(payment)
    {
      if(this.IsNotNull(payment))
      {
        this.$refs.addPaymentDlg.InitDlgData(payment);
      }
    },
    MarkInvoiceAsPayedSuccess(){
      this.connectMode = false;
      this.LoadData();
    },
    DeletePayment(payment){
        var amount = this.GetCurrencyString(payment.amount,payment.language_fk,payment.currency_kuerzel);
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PAYMENT.QUESTION.DELETE').replace("*1*",amount),
          accept: this.SendDeletePaymentRequest,
          parameters:{"id": payment.id,"txt": amount}
        });
    },
    SendDeletePaymentRequest: function(parameters)
    {
      this.$store.dispatch('payment/deletePayment', parameters.id)
      .then(response => {
        if(response.success === true)
        {
          this.LoadData();
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PAYMENT.SUCCESS.DELETE').replace("*1*",parameters.txt)});  
        }
        else
        {
          var text = this.$t('PAYMENT.WARNING.DELETE').replace("*1*",parameters.txt);
          text = text.replace("*2*",this.$t(response.text));
          this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
        }
      }, 
      error =>{

          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PAYMENT.ERROR.DELETE').replace("*1*",parameters.txt) + ' ' +error});  
      });
    },
  }
};

</script>